export enum DeviceTypeObservationType {
  'NEUTRAL' = 'NEUTRAL',
  'BAIT_COLLECT' = 'BAIT_COLLECT',
  'REFILL' = 'REFILL',
}

export enum DeviceTypeMeasurementMethod {
  'AMOUNT' = 'AMOUNT',
  'PERCENTAGE' = 'PERCENTAGE',
  'AMOUNT_PERCENTAGE' = 'AMOUNT_PERCENTAGE',
}
export interface DeviceType {
  parentCompanyId: number | null;
  name: string;
  description: string;
  measurementMethod?: DeviceTypeMeasurementMethod;
  color: string;
  deviceTypeObjects?: any[];
  deviceTypeActions?: any[];
  deviceTypeObservations?: any[];
  translations: {
    [key: string]: {
      [key: string]: string;
    };
  };
}
