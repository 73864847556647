import React from 'react';

import DeviceActions from '../DeviceActions';
import DeviceImages from '../DeviceImages';
import DeviceObjects from '../DeviceObjects';
import DeviceObservations from '../DeviceObservations';

const ActionsAndObjects = ({ deviceId, deviceTypeId }) => {
  return (
    <div>
      <DeviceActions deviceId={deviceId} deviceTypeId={deviceTypeId} />
      <DeviceObjects deviceId={deviceId} deviceTypeId={deviceTypeId} />
      <DeviceObservations deviceId={deviceId} deviceTypeId={deviceTypeId} />
      <DeviceImages deviceId={deviceId} />
    </div>
  );
};

export default ActionsAndObjects;
