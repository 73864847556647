import { Button } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';

const Inspection = () => {
  const { placeId, inspectionId } = useParams();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { observation: {} },
    fetchAction: () => apiService({}).get(`/places/${placeId}/inspections/${inspectionId}`),
  });

  const preview = async () => {
    const { data } = await apiService({
      baseUrl: process.env.REACT_APP_BACKEND_PDF_URL,
    }).post(`/reports/pdf/inspection/${inspectionId}?shouldRenderHtml=true`, {
      placeId,
    });

    const iframe = document.getElementById('report-preview') as HTMLIFrameElement;
    const html = data;
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();

    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    // @ts-ignore
    // const win = window.open(
    //   '',
    //   'Title',
    //   `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=${
    //   (window.screen.height - 400)} ,left=${(window.screen.width - 840)}`
    // );
    // win.document.body.innerHTML = html;
  };
  const iframeLoaded = () => {
    const iframe = document.getElementById('report-preview') as HTMLIFrameElement;

    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  };
  return (
    <div>
      <Button onClick={preview}>Preview</Button>
      <div style={{ width: '100%', minHeight: '100vh' }}>
        <iframe id="report-preview" style={{ width: '100%' }} onLoad={iframeLoaded}></iframe>
      </div>
    </div>
  );
};

export default Inspection;
