import React, { useCallback, useState } from 'react';
import { filter, get, sortBy } from 'lodash';
import map from 'lodash/map';
import { Line } from 'react-chartjs-2';
import {
  Button,
  Select,
  Title,
  MultiSelect,
  SimpleGrid,
  Alert,
  Flex,
  Checkbox,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import * as Sentry from '@sentry/browser';
import api from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';
import { htmlLegendPlugin } from 'shared/chartjs/htmlLegend';

import './styles.scss';

const sort = (unsorted?: any) => {
  if (!unsorted) {
    return undefined;
  }
  return sortBy(unsorted, s => {
    return new Date(s.date).getTime();
  });
};
interface RecomendationCategory {
  id?: string;
  name: string;
}

interface State {
  categories: RecomendationCategory[];
}

interface Props {}

const colors = [
  'rgba(255, 99, 132, 1.2)',
  'rgba(54, 162, 235, 1.2)',
  'rgba(255, 206, 86, 1.2)',
  'rgba(75, 192, 192, 1.2)',
  'rgba(153, 102, 255, 1.2)',
  'rgba(255, 159, 64, 1.2)',
  'rgba(55, 159, 64, 1.2)',
  'rgba(255, 59, 64, 1.2)',
  'rgba(55, 159, 164, 1)',
  'rgba(55, 19, 164, 1.2)',
  'rgba(255, 59, 164, 1.2)',
  'rgba(55, 59, 164, 1)',
];

export const getObjectsDataSet = (data, deviceId, c = false) => {
  const byObject = {};

  const labels = map(data, (data, date) => {
    return map(data.objects, (object, objectId) => {
      if (!byObject[object.deviceTypeObjectId]) {
        byObject[object.deviceTypeObjectId] = {
          dates: {},
          label: object.deviceTypeObject?.name,
          isDangerous: object.isDangerous,
        };
      }
      if (!byObject[object.deviceTypeObjectId].dates[date]) {
        byObject[object.deviceTypeObjectId].dates[date] = {};
      }
      byObject[object.deviceTypeObjectId].dates[date] = object;
    });
  });
  let index = 0;
  return map(byObject, (value, objectTypeId) => {
    const result = {
      label: `${value.label} (${value.isDangerous ? 'NB' : 'B'})`,
      fill: false,
      backgroundColor: colors[index],
      borderColor: colors[index],
      data: map(value.dates, (object, date) => {
        return { y: object.amount, x: object.dateId };
      }),
    };
    index++;
    return result;
  });
};

const getActionsDataSet = (data, deviceId) => {
  let index = 0;

  const byAction = {};

  const labels = map(data, (data, date) => {
    return map(data.actions, (action, actionId) => {
      if (!byAction[action.deviceTypeActionId]) {
        byAction[action.deviceTypeActionId] = {
          dates: {},
          label: action.deviceTypeAction?.name,
        };
      }
      if (!byAction[action.deviceTypeActionId].dates[date]) {
        byAction[action.deviceTypeActionId].dates[date] = {};
      }
      byAction[action.deviceTypeActionId].dates[date] = { ...action, dateId: data.date };
    });
  });
  return map(byAction, (value, actionTypeId) => {
    const result = {
      label: value.label,
      fill: false,
      backgroundColor: colors[index],
      borderColor: colors[index],
      data: map(value.dates, (action, date) => {
        return { y: action.amount, x: action.dateId };
      }),
    };
    index++;
    return result;
  });
};

const getObservationsDataSet = (data, deviceId) => {
  let index = 0;

  const byObservation = {};

  const labels = map(data, (data, date) => {
    return map(data.observations, (observation, observationId) => {
      if (!byObservation[observation.deviceTypeObservationId]) {
        byObservation[observation.deviceTypeObservationId] = {
          dates: {},
          label: observation.deviceTypeObservation?.name,
        };
      }
      if (!byObservation[observation.deviceTypeObservationId].dates[date]) {
        byObservation[observation.deviceTypeObservationId].dates[date] = {};
      }
      byObservation[observation.deviceTypeObservationId].dates[date] = observation;
    });
  });

  return map(byObservation, (value, observationTypeId) => {
    const result = {
      label: value.label,
      fill: false,
      backgroundColor: colors[index],
      borderColor: colors[index],
      data: map(value.dates, (observation, date) => {
        return observation.amount;
      }),
    };
    index++;
    return result;
  });
};

interface Response {
  placeId: string;
  byDevice: any;
}

const Details = (_: Props) => {
  const translations = useTranslations();
  const date = new Date();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const dateFromDefault = new Date(date.getFullYear(), date.getMonth(), 1);
  const dateToDefault = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [data, setData] = useState<Response>({} as Response);

  const getData = useCallback(async (query: string, placeId: string) => {
    setLoading(true);
    setLoaded(false);
    setError(undefined);
    try {
      const { data: response } = await api({
        timeout: 240000,
        baseUrl: process.env.REACT_APP_BACKEND_STATISTICS_URL,
      }).get(`/api/place/${placeId}/statistics?${query}`);
      setLoaded(true);
      setData(response);
    } catch (error) {
      setLoaded(false);
      setError(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const { data: placesData, isLoading: isPlacesLoading } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api({}).get(`/places`),
  });

  const form = useForm({
    initialValues: {
      placeId: '',
      dateFrom: dateFromDefault,
      dateTo: dateToDefault,
      deviceTypeId: undefined,
      deviceId: undefined,
    },

    validate: {
      placeId: value => (!value ? 'Wybierz placówkę' : null),
    },
  });
  const onSubmit = async (formValues: any) => {
    let df = new Date();
    let dt = new Date();
    if (formValues.dateFrom) {
      df = new Date(formValues.dateFrom);
      df.setHours(0, 0, 0, 0);
    }
    if (formValues.dateTo) {
      dt = new Date(formValues.dateTo);
      dt.setHours(23, 59, 59, 59);
    }
    const query = `from=${df}&to=${dt}&placeId=${formValues.placeId}`;
    getData(query, formValues.placeId);
    if (String(data.placeId) !== String(formValues.placeId)) {
      form.setFieldValue('deviceId', []);
      form.setFieldValue('deviceTypeId', undefined);
    }
  };

  const selectedDeviceId = form.values.deviceId;
  const selectedDeviceTypeId = form.values.deviceTypeId;

  const filteredDevices = filter(data?.byDevice, device =>
    form.values.deviceTypeId ? device.data.deviceTypeId === form.values.deviceTypeId : true,
  );
  const devicesOptions = map(filteredDevices, device => ({
    value: device.data?.id,
    label: device.data?.uniqueId,
  }));
  // data.devices
  //   .filter(
  //     device => device.deviceTypeId === values.deviceTypeId && device.placeId === values.placeId,
  //   )
  //   .map(device => ({
  //     value: device.id,
  //     label: device.description,
  //   }));

  const handleEquipMaintenanceFullscreen = useFullScreenHandle();
  const handlePestRelatedFullscreen = useFullScreenHandle();
  const handleObservationsFullscreen = useFullScreenHandle();

  const onSelectAll = useCallback(() => {
    form.setFieldValue(
      'deviceId',
      map(devicesOptions, device => device.value),
    );
  }, [form, devicesOptions]);

  return (
    <div>
      <ListHeading title={translations.global.statistics}></ListHeading>
      <AppContent>
        <form
          onSubmit={form.onSubmit(values => {
            onSubmit(values);
          })}>
          <SimpleGrid cols={4} mb={15}>
            <div>
              <Select
                label={translations.global.facility}
                data={placesData?.map(place => ({
                  value: String(place.id),
                  label: place.name,
                }))}
                searchable
                required
                {...form.getInputProps('placeId')}
                error={form.errors.placeId}
              />
              {data.placeId && String(form.values.placeId) !== String(data.placeId) && (
                <Alert variant="outline" mt={15}>
                  {translations.statistics.searchAgain}
                </Alert>
              )}
            </div>
            <DatePickerInput
              label={translations.global.dateFrom}
              required
              {...form.getInputProps('dateFrom')}
            />
            <DatePickerInput
              label={translations.global.dateTo}
              required
              {...form.getInputProps('dateTo')}
            />
            <Button type="submit" loading={isLoading} mt={25}>
              {translations.global.search}
            </Button>
          </SimpleGrid>
          {error && (
            <Alert variant="filled" color="red">
              {translations.global.somethingWentWrong}
            </Alert>
          )}
          {isLoaded && (
            <div>
              <Title mt={20} order={4} mb={0}>
                {translations.global.filters}
              </Title>
              <SimpleGrid cols={4} mb={15}>
                <Select
                  searchable
                  label={translations.global.deviceType}
                  data={map(data?.byDeviceType, device => ({
                    value: device.data?.id,
                    label: device.data?.name,
                  }))}
                  clearable
                  {...form.getInputProps('deviceTypeId')}
                />
                <Flex direction="column">
                  <MultiSelect
                    searchable
                    label={translations.global.device}
                    data={devicesOptions}
                    clearable
                    {...form.getInputProps('deviceId')}
                  />
                  <Button variant="outline" mt={10} onClick={onSelectAll}>
                    {translations.global.selectAll}
                  </Button>
                </Flex>
              </SimpleGrid>
            </div>
          )}
        </form>

        {isLoaded && (
          <div>
            <Title order={3} mt={25}>
              {translations.global.facility}
            </Title>
            <div className="row">
              <div className="col-md-4">
                <FullScreen handle={handleEquipMaintenanceFullscreen} className={'Fullscreen'}>
                  <div className="Fullscreen__title">
                    <Title order={4}>{translations.dashboard.equipmentMaintenance}</Title>
                    <Button
                      p={4}
                      onClick={
                        !handleEquipMaintenanceFullscreen.active
                          ? handleEquipMaintenanceFullscreen.enter
                          : handleEquipMaintenanceFullscreen.exit
                      }
                      size="small"
                      variant="outline">
                      {!handleEquipMaintenanceFullscreen.active && <i className="fas fa-expand" />}
                      {handleEquipMaintenanceFullscreen.active && <i className="fas fa-compress" />}
                    </Button>
                  </div>

                  <div style={{ maxHeight: 800 }}>
                    <div id="legend-container-details-1" />
                    <Line
                      data={{
                        labels: map(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          (data: any, date) => {
                            return data.date;
                          },
                        ),
                        datasets: getActionsDataSet(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          data.placeId,
                        ),
                      }}
                      options={{
                        pointRadius: 5,
                        tooltips: {
                          callbacks: {
                            title: function (tooltipItems, data) {
                              return data.datasets[tooltipItems[0].datasetIndex].data[
                                tooltipItems[0].index
                              ].x;
                            },
                          },
                        },
                        maintainAspectRatio: true,
                        responsive: true,
                        legend: {
                          position: 'top',
                        },
                        scales: {
                          xAxis: {
                            title: {
                              display: true,
                              text: translations.global.inspectionDate,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                          },
                          yAxis: {
                            title: {
                              display: true,
                              text: translations.global.amount,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                            ticks: {
                              stepSize: 10,
                            },
                          },
                        },
                        plugins: {
                          htmlLegend: {
                            containerID: 'legend-container-details-1',
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      plugins={[htmlLegendPlugin]}
                    />
                  </div>
                </FullScreen>
              </div>
              <div className="col-md-4">
                <FullScreen handle={handlePestRelatedFullscreen} className={'Fullscreen'}>
                  <div className="Fullscreen__title">
                    <Title order={4}>{translations.global.pestRelated}</Title>
                    <Button
                      p={4}
                      onClick={
                        !handlePestRelatedFullscreen.active
                          ? handlePestRelatedFullscreen.enter
                          : handlePestRelatedFullscreen.exit
                      }
                      size="small"
                      variant="outline">
                      {!handlePestRelatedFullscreen.active && <i className="fas fa-expand" />}
                      {handlePestRelatedFullscreen.active && <i className="fas fa-compress" />}
                    </Button>
                  </div>
                  <div style={{ maxHeight: 800 }}>
                    <div id="legend-container-details-2" />
                    <Line
                      data={{
                        labels: map(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          (data: any, date) => {
                            return data.date;
                          },
                        ),
                        datasets: getObjectsDataSet(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          data.placeId,
                        ),
                      }}
                      options={{
                        pointRadius: 5,
                        tooltips: {
                          callbacks: {
                            title: function (tooltipItems, data) {
                              return data.datasets[tooltipItems[0].datasetIndex].data[
                                tooltipItems[0].index
                              ].x;
                            },
                          },
                        },
                        maintainAspectRatio: true,
                        responsive: true,
                        legend: {
                          position: 'top',
                        },
                        scales: {
                          xAxis: {
                            title: {
                              display: true,
                              text: translations.global.inspectionDate,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                          },
                          yAxis: {
                            title: {
                              display: true,
                              text: translations.global.amount,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                            ticks: {
                              stepSize: 10,
                            },
                          },
                        },
                        plugins: {
                          htmlLegend: {
                            containerID: 'legend-container-details-2',
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      plugins={[htmlLegendPlugin]}
                    />
                  </div>
                </FullScreen>
              </div>
              <div className="col-md-4">
                <FullScreen handle={handleObservationsFullscreen} className={'Fullscreen'}>
                  <div className="Fullscreen__title">
                    <Title order={4}>{translations.global.observations}</Title>
                    <Button
                      p={4}
                      onClick={
                        !handleObservationsFullscreen.active
                          ? handleObservationsFullscreen.enter
                          : handleObservationsFullscreen.exit
                      }
                      size="small"
                      variant="outline">
                      {!handleObservationsFullscreen.active && <i className="fas fa-expand" />}
                      {handleObservationsFullscreen.active && <i className="fas fa-compress" />}
                    </Button>
                  </div>
                  <div style={{ maxHeight: 800 }}>
                    <div id="legend-container-details-3" />
                    <Line
                      data={{
                        labels: map(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          (data: any, date) => {
                            return data.date;
                          },
                        ),
                        datasets: getObservationsDataSet(
                          sort(get(data, `byPlace.${data.placeId}.dates`)),
                          data.placeId,
                        ),
                      }}
                      options={{
                        pointRadius: 5,
                        maintainAspectRatio: true,
                        responsive: true,
                        scales: {
                          xAxis: {
                            title: {
                              display: true,
                              text: translations.global.inspectionDate,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                          },
                          yAxis: {
                            title: {
                              display: true,
                              text: translations.global.amount,
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                            ticks: {
                              stepSize: 1,
                            },
                          },
                        },
                        plugins: {
                          htmlLegend: {
                            containerID: 'legend-container-details-3',
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      plugins={[htmlLegendPlugin]}
                    />
                  </div>
                </FullScreen>
              </div>
            </div>
            {form.values?.deviceTypeId && (
              <div className="row">
                <Flex mt={50}>
                  <Title order={3} mt={25} mb={10}>
                    {translations.global.deviceType}:{' '}
                    {get(data, `byDeviceType.${selectedDeviceTypeId}.data.name`)}
                  </Title>
                </Flex>
                <div className="col-md-4">
                  <Title order={4}>{translations.dashboard.equipmentMaintenance}</Title>
                  <div id="legend-container-details-4" />
                  <Line
                    data={{
                      labels: map(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        (data: any, date) => {
                          return data.date;
                        },
                      ),
                      datasets: getActionsDataSet(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        selectedDeviceTypeId,
                      ),
                    }}
                    options={{
                      pointRadius: 5,
                      tooltips: {
                        callbacks: {
                          title: function (tooltipItems, data) {
                            return data.datasets[tooltipItems[0].datasetIndex].data[
                              tooltipItems[0].index
                            ].x;
                          },
                        },
                      },
                      maintainAspectRatio: true,
                      scales: {
                        xAxis: {
                          title: {
                            display: true,
                            text: translations.global.inspectionDate,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                        },
                        yAxis: {
                          title: {
                            display: true,
                            text: translations.global.amount,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },
                      plugins: {
                        htmlLegend: {
                          containerID: 'legend-container-details-4',
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    plugins={[htmlLegendPlugin]}
                  />
                </div>
                <div className="col-md-4">
                  <Title order={4}>{translations.global.pestRelated}</Title>
                  <div id="legend-container-details-5" />
                  <Line
                    data={{
                      labels: map(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        (data: any, date) => {
                          return data.date;
                        },
                      ),
                      datasets: getObjectsDataSet(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        selectedDeviceTypeId,
                      ),
                    }}
                    options={{
                      pointRadius: 5,
                      tooltips: {
                        callbacks: {
                          title: function (tooltipItems, data) {
                            return data.datasets[tooltipItems[0].datasetIndex].data[
                              tooltipItems[0].index
                            ].x;
                          },
                        },
                      },
                      maintainAspectRatio: true,
                      scales: {
                        xAxis: {
                          title: {
                            display: true,
                            text: translations.global.inspectionDate,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                        },
                        yAxis: {
                          title: {
                            display: true,
                            text: translations.global.amount,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },
                      plugins: {
                        htmlLegend: {
                          containerID: 'legend-container-details-5',
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    plugins={[htmlLegendPlugin]}
                  />
                </div>
                <div className="col-md-4">
                  <Title order={4}>{translations.global.observations}</Title>
                  <div id="legend-container-details-6" />
                  <Line
                    data={{
                      labels: map(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        (data: any, date) => {
                          return data.date;
                        },
                      ),
                      datasets: getObservationsDataSet(
                        sort(get(data, `byDeviceType.${selectedDeviceTypeId}.dates`)),
                        selectedDeviceTypeId,
                      ),
                    }}
                    options={{
                      pointRadius: 5,
                      maintainAspectRatio: true,
                      scales: {
                        xAxis: {
                          title: {
                            display: true,
                            text: translations.global.inspectionDate,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                        },
                        yAxis: {
                          title: {
                            display: true,
                            text: translations.global.amount,
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },
                      plugins: {
                        htmlLegend: {
                          containerID: 'legend-container-details-6',
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    plugins={[htmlLegendPlugin]}
                  />
                </div>
              </div>
            )}
            {form.values?.deviceId?.length && (
              <div>
                {form.values?.deviceId.map(deviceId => {
                  return (
                    <div className="row" key={deviceId}>
                      <Flex>
                        <Title order={3} mt={25}>
                          {translations.global.device}{' '}
                          {get(data, `byDevice.${deviceId}.data.uniqueId`)}
                        </Title>
                      </Flex>
                      <div className="col-md-4">
                        <Title order={4}>{translations.dashboard.equipmentMaintenance}</Title>
                        <div id="legend-container-details-7" />
                        <Line
                          data={{
                            labels: map(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              (data: any, date) => {
                                return data.date;
                              },
                            ),
                            datasets: getActionsDataSet(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              deviceId,
                            ),
                          }}
                          options={{
                            pointRadius: 5,
                            tooltips: {
                              callbacks: {
                                title: function (tooltipItems, data) {
                                  return data.datasets[tooltipItems[0].datasetIndex].data[
                                    tooltipItems[0].index
                                  ].x;
                                },
                              },
                            },
                            maintainAspectRatio: true,
                            scales: {
                              xAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.inspectionDate,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                              },
                              yAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.amount,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                                ticks: {
                                  stepSize: 1,
                                },
                              },
                            },
                            plugins: {
                              htmlLegend: {
                                containerID: 'legend-container-details-7',
                              },
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          plugins={[htmlLegendPlugin]}
                        />
                      </div>
                      <div className="col-md-4">
                        <Title order={4}>{translations.global.pestRelated}</Title>
                        <div id="legend-container-details-8" />
                        <Line
                          data={{
                            labels: map(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              (data: any, date) => {
                                return data.date;
                              },
                            ),
                            datasets: getObjectsDataSet(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              deviceId,
                              true,
                            ),
                          }}
                          options={{
                            pointRadius: 5,
                            tooltips: {
                              callbacks: {
                                title: function (tooltipItems, data) {
                                  return data.datasets[tooltipItems[0].datasetIndex].data[
                                    tooltipItems[0].index
                                  ].x;
                                },
                              },
                            },
                            maintainAspectRatio: true,
                            scales: {
                              xAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.inspectionDate,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                              },
                              yAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.amount,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                                ticks: {
                                  stepSize: 1,
                                },
                              },
                            },
                            plugins: {
                              htmlLegend: {
                                containerID: 'legend-container-details-8',
                              },
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          plugins={[htmlLegendPlugin]}
                        />
                      </div>
                      <div className="col-md-4">
                        <Title order={4}>{translations.global.observations}</Title>
                        <div id="legend-container-details-9" />
                        <Line
                          data={{
                            labels: map(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              (data: any, date) => {
                                return data.date;
                              },
                            ),
                            datasets: getObservationsDataSet(
                              sort(get(data, `byDevice.${deviceId}.dates`)),
                              deviceId,
                            ),
                          }}
                          options={{
                            pointRadius: 5,
                            maintainAspectRatio: true,
                            scales: {
                              xAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.inspectionDate,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                              },
                              yAxis: {
                                title: {
                                  display: true,
                                  text: translations.global.amount,
                                  font: {
                                    size: 14,
                                    weight: 'bold',
                                  },
                                },
                                ticks: {
                                  stepSize: 1,
                                },
                              },
                            },
                            plugins: {
                              htmlLegend: {
                                containerID: 'legend-container-details-9',
                              },
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          plugins={[htmlLegendPlugin]}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </AppContent>
    </div>
  );
};
export default Details;
