import { translationService } from 'shared/translations/translations';

export const AUTHORIZED_PATH = '/app';
export const UNAUTHORIZED_PATH = '/auth';

export const routes = {
  app: {
    default: {
      title: 'Pulpit',
      translationsTitle: translations => translations?.routes.dashboard,
      path: '/app',
    },
  },
  statistics: {
    default: {
      title: 'Statistics',
      path: '/app/statistics/',
      to: (query = '') => `/app/statistics/${query}`,
    },
  },
  lora: {
    default: {
      title: 'Lora Devices',
      path: '/app/lora',
    },
    gateways: {
      default: {
        title: 'Lora gateways',
        path: '/app/lora/gateways',
      },
      details: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/gateways/details/:loraDeviceUuid`,
        to: id => `/app/lora/gateways/details/${id}`,
      },
      edit: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/gateways/edit/:loraDeviceUuid`,
        to: id => `/app/lora/gateways/edit/${id}`,
      },
      create: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/gateways/create`,
      },
    },
    devices: {
      default: {
        title: 'Lora Devices',
        path: '/app/lora/devices',
      },
      details: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/devices/details/:loraDeviceUuid`,
        to: id => `/app/lora/devices/details/${id}`,
      },
      edit: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/devices/edit/:loraDeviceUuid`,
        to: id => `/app/lora/devices/edit/${id}`,
      },
      create: {
        translationsTitle: translations => translations?.global.deviceTypes,
        title: 'Edytuj',
        path: `/app/lora/devices/create`,
      },
    },
  },
  schedule: {
    default: {
      title: 'Schedule',
      translationsTitle: translations => translations?.schedule.title,
      path: '/app/schedule',
    },
  },
  admin: {
    default: {
      title: 'Admin',
      path: '/app/admin/',
    },
    devices: {
      default: {
        title: 'Devices - Admin',
        path: '/app/admin/devices',
      },
      types: {
        title: 'Device Types',
        path: '/app/admin/devices/types',
        manage: id => ({
          translationsTitle: translations => translations?.global.deviceTypes,
          title: 'Edytuj',
          path: `/app/admin/devices/types/edit/${id}`,
        }),
        create: {
          translationsTitle: translations => translations?.global.deviceTypes,
          title: 'Dodaj',
          path: `/app/admin/devices/types/create`,
        },
      },
      actions: {
        title: 'Device Actions',
        path: '/app/admin/devices/actions',
      },
      objects: {
        title: 'Device Objects',
        path: '/app/admin/devices/objects',
      },
      observations: {
        title: 'Device Observations',
        path: '/app/admin/devices/observations',
      },
      // details: {
      //   title: 'Devices - Details',
      //   path: '/app/admin/devices/details/:id',
      //   to: id => `/app/admin/devices/details/${id}`,
      // },
      // edit: {
      //   title: 'Devices - Admin',
      //   path: '/app/admin/devices/edit/:id',
      //   to: id => `/app/admin/devices/edit/${id}`,
      // },
    },
    mixtures: {
      default: {
        title: 'Mixtures - Admin',
        path: '/app/admin/mixtures',
      },
      create: {
        title: 'Mixtures - Create',
        path: '/app/admin/mixtures/create',
      },
      details: {
        title: 'Mixtures - Details',
        path: '/app/admin/mixtures/details/:id',
        to: id => `/app/admin/mixtures/details/${id}`,
      },
      edit: {
        title: 'Mixtures - Admin',
        path: '/app/admin/mixtures/edit/:id',
        to: id => `/app/admin/mixtures/edit/${id}`,
      },
    },
    visualInspection: {
      default: {
        title: 'Visual inspection - Admin',
        path: '/app/admin/visual-inspection',
      },
      create: {
        title: 'Visual inspection - Create',
        path: '/app/admin/visual-inspection/create',
      },
      details: {
        title: 'Visual inspection - Details',
        path: '/app/admin/visual-inspection/details/:id',
        to: id => `/app/admin/visual-inspection/details/${id}`,
      },
      edit: {
        title: 'Visual inspection - Admin',
        path: '/app/admin/visual-inspection/edit/:id',
        to: id => `/app/admin/visual-inspection/edit/${id}`,
      },
    },
    recommendation: {
      default: {
        title: 'Recommendations - Admin',
        path: '/app/admin/recommendations',
      },
      create: {
        title: 'Recommendations - Create',
        path: '/app/admin/recommendations/create',
      },
      details: {
        title: 'Recommendations - Details',
        path: '/app/admin/recommendations/details/:id',
        to: id => `/app/admin/recommendations/details/${id}`,
      },
      edit: {
        title: 'Recommendations - Admin',
        path: '/app/admin/recommendations/edit/:id',
        to: id => `/app/admin/recommendations/edit/${id}`,
      },
    },
    settings: {
      default: {
        title: 'Settings - Admin',
        path: '/app/admin/settings',
      },
    },
    test: {
      default: {
        title: 'Test - Admin',
        path: '/app/admin/test',
      },
    },
  },
  recommendation: {
    default: {
      title: 'Recommendations',
      path: '/app/recommendations',
    },
    statistics: {
      default: {
        title: 'Recommendations',
        path: '/app/recommendations/statistics',
      },
    },
    place: {
      title: 'Recommendations - Place',
      path: '/app/recommendations/:placeId',
      to: id => `/app/recommendations/${id}`,
    },
    create: {
      title: 'Recommendations - Create',
      path: '/app/recommendations/:placeId/create',
      to: placeId => `/app/recommendations/${placeId}/create`,
    },
    details: {
      title: 'Recommendations - Details',
      path: '/app/recommendations/:placeId/details/:id',
      to: (placeId, id) => `/app/recommendations/${placeId}/details/${id}`,
    },
    edit: {
      title: 'Recommendations - Details',
      path: '/app/recommendations/:placeId/details/:id/edit',
      to: (placeId, id) => `/app/recommendations/${placeId}/details/${id}/edit`,
    },
  },
  dashboard: {
    default: {
      title: 'Pulpit v2.0',
      path: '/app/dashboard',
    },
    company: {
      title: 'Pulpit v2.0',
      path: '/app/dashboard/company/:companyId',
    },
    place: {
      title: 'Pulpit v2.0',
      path: '/app/dashboard/company/:companyId/place/:placeId',
    },
  },
  reports: {
    default: {
      title: 'Raporty',
      translationsTitle: translations => translations?.reports?.reports,
      path: '/app/reports',
      to: (query = '') => `/app/reports${query}`,
    },
    generate: {
      translationsTitle: translations => translations?.reports.generateReports,
      title: 'Wygeneruj raport',
      path: '/app/reports/generate',
    },
  },
  auth: {
    default: {
      title: 'Auth',
      path: '/auth',
    },
    login: {
      title: 'Login',
      path: '/auth/login',
    },
    forgotPassword: {
      title: 'Forgot Password',
      path: '/auth/forgot-password',
    },
    resetPassword: {
      title: 'Reset Password',
      path: '/auth/reset-password',
    },
  },
  qrCodes: {
    default: {
      translationsTitle: translations => translations?.qrCodes.title,
      title: 'Konto',
      path: '/app/qrcodes',
    },
    details: {
      translationsTitle: translations => translations?.qrCodes.title,
      title: 'QrCodes',
      path: '/app/qrcodes/batch/:batchId',
      to: id => `/app/qrcodes/batch/${id}`,
    },
  },
  account: {
    default: {
      translationsTitle: translations => translations?.global.account,
      title: 'Konto',
      path: '/app/account',
    },
    notifications: {
      translationsTitle: translations => translations?.global.notifications,
      title: 'Notyfikacje',
      path: '/app/account/notifications',
    },
    changePassword: {
      translationsTitle: translations => translations?.global.account,
      title: 'Zmień hasło',
      path: '/app/account/change-password/',
    },
  },
  deviceTypes: {
    default: {
      title: 'Typy urządzeń',
      translationsTitle: translations => translations?.global.deviceTypes,
      path: '/app/device-types',
    },
    list: {
      translationsTitle: translations => translations?.global.deviceTypes,
      title: 'Typy urządzeń',
      path: '/app/device-types',
    },
    manage: id => ({
      translationsTitle: translations => translations?.global.deviceTypes,
      title: 'Edytuj',
      path: `/app/device-types/edit/${id}`,
    }),
    create: {
      translationsTitle: translations => translations?.global.deviceTypes,
      title: 'Dodaj',
      path: `/app/device-types/create`,
    },
    actions: {
      title: 'Device Actions',
      path: '/app/device-types/actions',
    },
    objects: {
      title: 'Device Objects',
      path: '/app/device-types/objects',
    },
    observations: {
      title: 'Device Observations',
      path: '/app/device-types/observations',
    },
  },
  devices: {
    default: {
      title: 'Urządzenia',
      translationsTitle: translations => translations?.global.devices,
      path: '/app/devices',
    },
    list: {
      title: 'Urządzenia',
      translationsTitle: translations => translations?.global.devices,
      path: '/app/devices',
    },
    create: {
      title: 'Urządzenia',
      translationsTitle: translations => translations?.global.devices,
      path: '/app/devices/create',
    },
    edit: {
      title: 'Urządzenia',
      translationsTitle: translations => translations?.global.devices,
      path: '/app/devices/edit/:id',
    },
    manage: {
      title: 'Urządzenie',
      translationsTitle: translations => translations?.global.devices,
      path: '/app/devices/edit/:id',
      to: id => `/app/devices/edit/${id}`,
    },
  },
  users: {
    default: {
      translationsTitle: translations => translations?.global.users,
      title: 'Użytkownicy',
      path: '/app/users',
    },
    list: {
      translationsTitle: translations => translations?.global.users,
      title: 'Użytkownicy',
      path: '/app/users',
    },
    manage: {
      translationsTitle: translations => translations?.global.users,
      title: 'Edytuj użytkownika',
      path: '/app/users/edit/:id',
      to: id => `/app/users/edit/${id}`,
    },
    create: {
      title: 'Dodaj',
      path: '/app/users/create/',
    },
  },
  places: {
    default: {
      translationsTitle: translations => translations?.global.facilities,
      title: 'Obiekty',
      path: '/app/places',
    },
    list: {
      translationsTitle: translations => translations?.global.facilities,
      title: 'Obiekty',
      path: '/app/places',
    },
    manage: {
      translationsTitle: translations => translations?.global.facilities,
      title: 'Obiekty',
      path: '/app/places/edit/:id',
      to: (placeId, query = '') => `/app/places/edit/${placeId}${query}`,
    },
    inspections: {
      default: {
        title: 'Inspekcje',
        path: '/app/places/edit/:placeId/inspections',
        to: placeId => `/app/places/edit/${placeId}/inspections`,
      },
      connect: {
        title: 'Inspekcja',
        path: '/app/places/edit/:placeId/inspections/connect',
        to: (placeId, inspectionId) =>
          `/app/places/edit/${placeId}/inspections/connect?inspectionId1=${inspectionId}`,
      },
      manage: {
        title: 'Inspekcja',
        path: '/app/places/edit/:placeId/inspections/:inspectionId',
        to: (placeId, inspectionId) => `/app/places/edit/${placeId}/inspections/${inspectionId}`,
      },
      // translationsTitle: translations => translations?.global.facilities,
    },
    inspection: {
      default: {
        title: 'Inspekcja',
        path: '/app/places/edit/:placeId/inspection/:inspectionId',
        to: (placeId, inspectionId) => `/app/places/edit/${placeId}/inspection/${inspectionId}`,
      },
      // translationsTitle: translations => translations?.global.facilities,
      title: 'Inspekcja',
      path: '/app/places/edit/:placeId/inspection/:inspectionId',
      to: (placeId, inspectionId) => `/app/places/edit/${placeId}/inspection/${inspectionId}`,
    },
    devices: {
      default: {
        title: 'Urządzenia',
        path: '/app/places/edit/:placeId/devices/',
        to: placeId => `/app/places/edit/${placeId}/devices`,
      },
      edit: {
        title: 'Urządzenie edycja',
        path: '/app/places/edit/:placeId/devices/:deviceUuid/edit',
        to: (placeId, deviceUuid) => `/app/places/edit/${placeId}/devices/${deviceUuid}/edit`,
      },
      create: {
        title: 'Urządzenia',
        path: '/app/places/edit/:placeId/devices/create',
        to: placeId => `/app/places/edit/${placeId}/devices/create`,
      },
      details: {
        // translationsTitle: translations => translations?.global.facilities,
        title: 'Urządzenie',
        path: '/app/places/edit/:placeId/devices/:deviceUuid',
        to: (placeId, deviceUuid) => `/app/places/edit/${placeId}/devices/${deviceUuid}`,
      },
    },
    create: {
      title: 'Obiekty',
      translationsTitle: translations => translations?.global.facilities,
      path: '/app/places/create/',
    },
    radioOptions: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/radio-options',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/radio-options`,
      },
    },
    files: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/files',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/files`,
      },
    },
    map: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/map',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/map`,
      },
    },
    heatmap: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/heatmap',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/heatmap`,
      },
    },
    visualInspection: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/visual-inspection',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/visual-inspection`,
      },
    },
    zones: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/zones',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/zones`,
      },
    },
    objectsLimit: {
      default: {
        // title: '',
        path: '/app/places/edit/:placeUuid/objects-limit',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/objects-limit`,
      },
    },
    interventions: {
      default: {
        title: 'Interwencje',
        path: '/app/places/edit/:placeUuid/interventions',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/interventions`,
      },
      create: {
        translationsTitle: translations => translations?.interventions.createIntervention,
        title: 'Stwórz protokół interwencyjny',
        path: '/app/places/edit/:placeId/:placeUuid/interventions/create',
        to: (placeId: number, placeUuid: string) =>
          `/app/places/edit/${placeId}/${placeUuid}/interventions/create`,
      },
      edit: {
        translationsTitle: translations => translations?.interventions.editIntervention,
        title: 'Edytuj protokół interwencyjny',
        path: '/app/places/edit/:placeId/:placeUuid/interventions/edit/:interventionUuid',
        to: (placeId: number, placeUuid: string, interventionUuid: string) =>
          `/app/places/edit/${placeId}/${placeUuid}/interventions/edit/${interventionUuid}`,
      },
    },
    schedule: {
      default: {
        title: 'Harmonogram',
        path: '/app/places/edit/:placeUuid/schedule',
        to: (placeId: string | number) => `/app/places/edit/${placeId}/schedule`,
      },
    },
  },
  companies: {
    default: {
      translationsTitle: translations => translations?.global.companies,
      title: 'Firmy',
      path: '/app/companies',
    },
    create: {
      translationsTitle: translations => translations?.global.companies,
      title: 'Firmy',
      path: '/app/companies/create/',
    },
    list: {
      translationsTitle: translations => translations?.global.companies,
      title: 'Firmy',
      path: '/app/companies',
    },
    manage: {
      translationsTitle: translations => translations?.global.companies,
      title: 'Firmy',
      path: '/app/companies/edit/:id',
      to: id => `/app/companies/edit/${id}`,
    },
  },
};
