import React, { useCallback, useEffect, useState } from 'react';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import filter from 'lodash/filter';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import { readableDate } from 'shared/utils/date';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { useTranslations } from 'shared/translations/useTranslations';
import {
  Group,
  Button,
  Badge,
  Tooltip,
  Popover,
  Text,
  Title,
  Alert,
  Pagination,
  Select,
  Flex,
} from '@mantine/core';
import { useSelector } from 'react-redux';
import { isAnyAdminByAuthState, isSuperAdminByAuthState } from 'shared/utils/user';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { usePagination } from '@mantine/hooks';
import { PlaceInspectionStatus } from 'shared/types/PlaceInspection';

interface Props {
  placeId: string | number;
}
interface State {
  inspections: [];
  totalPages: number;
}

export const getCheckedDevices = (devices = []) => {
  const inspectionDevices =
    filter(
      flatMap(devices, device => get(device, 'inspectionDevice.0')),
      device => device?.status === 'CHECKED',
    ) || [];
  return inspectionDevices.length;
};

const Inspections = (props: Props) => {
  const translations = useTranslations();
  const [isGenerating, setGenerating] = useState<number | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isUnapproving, setUnapproving] = useState<number | undefined>(undefined);
  const [isDownloading, setDownloading] = useState<number | undefined>(undefined);
  const [data, setData] = useState<State>({
    inspections: [],
    totalPages: 0,
  });
  const pagination = usePagination({ total: data.totalPages, siblings: 3 });
  const [filterStatus, setFilterStatus] = useState<PlaceInspectionStatus>(
    PlaceInspectionStatus.COMPLETED,
  );

  const fetchData = useCallback(
    async () => {
      if (isLoading) return;
      try {
        setError(false);
        setLoading(true);
        const { data } = await api({ timeout: 120000 }).get(
          `places/${props.placeId}/inspections?page=${pagination.active}&limit=10&status=${filterStatus}`,
        );
        setData(data);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [props.placeId, filterStatus, isLoading, pagination.active],
  );

  useEffect(() => {
    fetchData();
  }, [pagination.active, filterStatus]);

  const onDownloadPdf = (inspection, report) => async () => {
    try {
      setDownloading(report.id);
      const {
        data: { url },
      } = await api({}).get(
        `/places/${props.placeId}/inspections/${inspection.id}/reports/${report.id}/signed-url`,
      );

      await saveFileFromUrl(
        url,
        `${get(inspection, 'name')}_${get(inspection, 'place.name')}_${readableDate(
          inspection.createdAt,
        )}`.toLowerCase(),
      );
    } catch {
    } finally {
      setDownloading(undefined);
    }
  };

  const onGeneratePdf = inspection => async () => {
    try {
      setGenerating(inspection.id);
      const {
        data: { url },
      } = await api({ timeout: 120000, baseUrl: process.env.REACT_APP_BACKEND_PDF_URL }).post(
        `/reports/pdf/inspection/${inspection.id}`,
        {
          placeId: props.placeId,
        },
      );
      await fetchData(pagination.active);
    } catch {
    } finally {
      setGenerating(undefined);
    }
  };

  const unApproveInspection = inspection => async () => {
    try {
      setUnapproving(inspection.id);
      const {
        data: { url },
      } = await api({}).post(
        `/api/places/${inspection.placeId}/inspections/${inspection.id}/unapprove`,
        {
          placeId: props.placeId,
        },
      );
      await fetchData(pagination.active);
    } catch {
    } finally {
      setUnapproving(undefined);
    }
  };

  const { isAnyAdmin } = useSelector(state => ({
    isSuperAdmin: isSuperAdminByAuthState(state.auth),
    isAnyAdmin: isAnyAdminByAuthState(state.auth),
  }));
  const [showCancelReportPopover, setShowCancelReportPopover] = useState();
  const inspectionStatusColor = (status: PlaceInspectionStatus) => {
    switch (status) {
      case PlaceInspectionStatus.COMPLETED:
        return 'green';
      case PlaceInspectionStatus.IN_PROGRESS:
        return 'yellow';
      case PlaceInspectionStatus.CANCELLED:
        return 'red';
      default:
        return 'gray';
    }
  };

  const filterStatusOptions = Object.keys(PlaceInspectionStatus).map(status => ({
    value: PlaceInspectionStatus[status],
    label: translations.inspections.status[PlaceInspectionStatus[status]],
  }));

  const onChangeStatus = useCallback(
    (value: string | null) => {
      pagination.setPage(1);
      setFilterStatus(value as PlaceInspectionStatus);
    },
    [setFilterStatus, pagination],
  );

  return (
    <div>
      <ListHeading title={translations.global.inspections}></ListHeading>
      <AppContent>
        <Flex mb={20}>
          <Select
            label={translations.global.status}
            data={filterStatusOptions}
            value={filterStatus}
            onChange={onChangeStatus}
          />
        </Flex>
        <Table isLoading={isLoading}>
          <Thead>
            <Th>{translations.global.name}</Th>
            <Th>{translations.global.note}</Th>
            <Th>{translations.global.status}</Th>
            <Th>{translations.inspections.startedAt}</Th>
            <Th>{translations.inspections.finishedAt}</Th>
            <Th>{translations.global.user}</Th>
            <Th>{translations.inspections.checkedDevices}</Th>
            <Th>{translations.inspections.approved}</Th>
            <Th>{translations.global.report}</Th>
          </Thead>
          <Tbody>
            {data.inspections.map(inspection => (
              <Tr key={inspection.id}>
                <Td>{inspection.name}</Td>
                <Td>{inspection.description}</Td>
                <Td>
                  <Badge color={inspectionStatusColor(inspection.status)}>
                    {translations.inspections.status[inspection.status]}
                  </Badge>
                </Td>
                <Td>{readableDate(inspection.startedAt)}</Td>
                <Td>{readableDate(inspection.finishedAt)}</Td>
                <Td>
                  {get(inspection, 'user.name')} {get(inspection, 'user.surname')}
                </Td>
                <Td>
                  {getCheckedDevices(get(inspection, 'devices'))} /{' '}
                  {get(inspection, 'devices.length')}
                </Td>
                <Td>
                  {get(inspection, 'approved') ? (
                    <Tooltip
                      label={`${inspection.approvedBy?.name} ${
                        inspection.approvedBy?.surname
                      }, ${readableDate(inspection.approvedAt)}`}>
                      <Badge color="green">{translations.global.yes}</Badge>
                    </Tooltip>
                  ) : (
                    <Badge color="red">{translations.global.no}</Badge>
                  )}
                </Td>
                <Td>
                  {inspection.status === PlaceInspectionStatus.COMPLETED && (
                    <Group>
                      {get(inspection, 'reports.0.url') ? (
                        <Button
                          onClick={onDownloadPdf(inspection, get(inspection, 'reports.0'))}
                          loading={isDownloading === get(inspection, 'reports.0.id')}>
                          {translations.global.download}
                        </Button>
                      ) : (
                        <Button
                          onClick={onGeneratePdf(inspection)}
                          loading={isGenerating === inspection.id}
                          variant="outline">
                          {translations.global.generate}
                        </Button>
                      )}
                      {get(inspection, 'approved') && isAnyAdmin && (
                        <Popover
                          key={inspection.id}
                          opened={showCancelReportPopover === inspection.id}
                          onClose={() => setShowCancelReportPopover(false)}
                          width={260}
                          position="bottom"
                          withArrow>
                          <Popover.Target>
                            <Button
                              color="red"
                              variant="outline"
                              onClick={() =>
                                setShowCancelReportPopover(o => (o ? undefined : inspection.id))
                              }>
                              {translations.inspections.unapproveInspection}
                            </Button>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Title order={5}>{translations.inspections.confirmUnapproval}</Title>
                            <Text size="sm" mb={10}>
                              {translations.inspections.confirmUnapprovalDesc}
                            </Text>
                            <Group>
                              <Button
                                size="xs"
                                onClick={unApproveInspection(inspection)}
                                loading={isUnapproving === inspection.id}
                                disabled={isUnapproving === inspection.id}
                                color="red">
                                {translations.global.confirm}
                              </Button>
                              <Button
                                size="xs"
                                onClick={() => setShowCancelReportPopover(undefined)}
                                variant="outline">
                                {translations.global.cancel}
                              </Button>
                            </Group>
                          </Popover.Dropdown>
                        </Popover>
                      )}
                      {get(inspection, 'reports.0.url') && (
                        <Button
                          onClick={onGeneratePdf(inspection)}
                          loading={isGenerating === inspection.id}
                          variant="outline">
                          {translations.global.generate}
                        </Button>
                      )}
                    </Group>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          mt={20}
          value={pagination.active}
          onChange={pagination.setPage}
          total={data.totalPages}
        />
        {error && <Alert color="red">{translations.global.somethingWentWrong}</Alert>}
      </AppContent>
    </div>
  );
};
export default Inspections;
