import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import map from 'lodash/map';
import get from 'lodash/get';
import { ActionIcon, Group, Paper, LoadingOverlay, Title } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import { useSelector } from 'react-redux';

import { readableDate } from 'shared/utils/date';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { isAnyAdminByAuthState } from 'shared/utils/user';

import EditObjectModal from '../EditObjectModal';
import DeleteObjectModal from '../DeleteObjectModal';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

interface ObjectsData {
  objects: {
    id: string;
    name: string;
    amount: string;
    note: string;
    createdAt: string;
    inspection?: {
      name: string;
    };
  }[];
}
const DeviceObjects = ({ deviceId, deviceTypeId }) => {
  const {
    data: dataObjects,
    isLoading: isLoadingObjects,
    isLoaded: isLoadedObjects,
    error: isErrorObjects,
    refresh: refreshObjects,
  } = useFetch<ObjectsData>({
    initialValue: { action: {} },
    fetchAction: () => apiService({}).get(`/api/v3/devices/${deviceId}/objects`),
  });
  const isAdmin = useSelector(state => isAnyAdminByAuthState(state.auth));
  const classes = useStyles();
  const translations = useTranslations();
  const [editObjectId, setEditObjectId] = useState(undefined);
  const [deleteObjectId, setDeleteObjectId] = useState(undefined);
  const handleClose = () => {
    setEditObjectId(undefined);
    setDeleteObjectId(undefined);
  };

  const handleRefreshObject = () => {
    setEditObjectId(undefined);
    setDeleteObjectId(undefined);
    refreshObjects();
  };

  const handleOnEditObject = (id: string) => () => {
    setEditObjectId(id);
  };

  const handleOnDeleteObject = (id: string) => () => {
    setDeleteObjectId(id);
  };

  return (
    <div>
      <EditObjectModal
        objectId={editObjectId}
        deviceId={deviceId}
        deviceTypeId={deviceTypeId}
        refreshParent={handleRefreshObject}
        onClose={handleClose}
      />
      <DeleteObjectModal
        objectId={deleteObjectId}
        deviceId={deviceId}
        refreshParent={handleRefreshObject}
        onClose={handleClose}
      />

      <Title order={3} mt={40} mb={20}>
        {translations.devices.objects}
      </Title>
      <div style={{ position: 'relative', minHeight: '300px' }}>
        <Paper className={classes.root} shadow='xs'>
          <LoadingOverlay visible={isLoadingObjects} />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.devices.amount}</TableCell>
                <TableCell>{translations.devices.object}</TableCell>
                <TableCell>{translations.global.user}</TableCell>
                <TableCell>{translations.devices.date}</TableCell>
                <TableCell>{translations.devices.note}</TableCell>
                <TableCell>{translations.devices.inspection}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isLoadedObjects && (
              <TableBody>
                {map(dataObjects.objects, row => (
                  <TableRow key={`object-${row.id}`}>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{get(row, 'deviceTypeObject.name')}</TableCell>
                    <TableCell>
                      {get(row, 'user.name')} {get(row, 'user.surname')}
                    </TableCell>
                    <TableCell>{readableDate(row.createdAt)}</TableCell>
                    <TableCell>{row.note}</TableCell>
                    <TableCell>{row.inspection?.name || '-'}</TableCell>
                    <TableCell>
                      {isAdmin && (
                        <Group>
                          <ActionIcon variant="transparent" onClick={handleOnEditObject(row.id)}>
                            <Pencil size={16} />
                          </ActionIcon>
                          <ActionIcon variant="transparent" onClick={handleOnDeleteObject(row.id)}>
                            <Trash size={16} />
                          </ActionIcon>
                        </Group>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </div>
    </div>
  );
};

export default DeviceObjects;
